<template>
  <v-card tile :elevation="0" class="crud-box list-orders px-4">
    <h1>Check Orders</h1>
    <v-layout v-show="showSearch" wrap class="px-4 pt-4">
      <!---->
      <v-layout wrap class="search-fields search-fields-page">
        <v-textarea placeholder="List Order Code or Tracking Number, each code in 1 line"
                    style="width: 500px"
                    rows="3"
                    dense outlined
                    v-model="list_code"></v-textarea>
        <v-spacer style="flex-grow: 100000000000000 !important;"></v-spacer>
      </v-layout>
      <v-btn color="primary" @click="doSearch()">
        <v-icon>mdi-magnify</v-icon>
        Search
      </v-btn>
    </v-layout>

    <!--card content-->
    <v-card tile :elevation="1" :disabled="tblLoading" outlined class="block-tbl mx-4">
      <v-data-table :headers="tblHeaderFilterByRole" :items="listItems" :server-items-length="tblPagination.total"
                    :loading="tblLoading"
                    mobile-breakpoint="0"
                    :footer-props="tblConfig.footerProps" @dblclick:row="onClickRow" :options.sync="tblOptions"
                    :loading-text="tblConfig.text.loading" v-model="pickedItems"
                    :show-select="!isCollaborator && !isPackager && tblConfig.showSelect">
        <template v-slot:no-data>
          {{ tblConfig.text.noItem }}
        </template>

        <template v-slot:item.no="{item}">
          {{ getIndexOfItem(item) }}
        </template>

        <template v-slot:item.name="{item}">
          <v-tooltip bottom v-if="item.note">
            <template v-slot:activator="{ on, attrs }">
              <div class="block-item-name" v-bind="attrs" v-on="on">
                <div class="item-name">{{ item.name }}</div>
                <div class="item-code">{{ item.code }}</div>
              </div>
            </template>
            <span>{{ item.note }}</span>
          </v-tooltip>
          <template v-else>
            <div class="block-item-name">
              <div class="item-name">{{ item.name }}</div>
              <div class="item-code">{{ item.code }}</div>
            </div>
          </template>
        </template>
        <template v-slot:item.paid_fee="{item}">
          <money-format :value="getPaidFee(item)" style="display: inline-block;"
                        :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format>
        </template>
        <template v-slot:item.remain_fee="{item}">
          <money-format :value="getRemainFee(item)" style="display: inline-block;"
                        :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format>
        </template>
        <template v-slot:item.total_fee="{item}">
          <money-format :value="getTotalFee(item)" style="display: inline-block;"
                        :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format>
        </template>
        <template v-slot:item.status="{item}">
          <div v-if="item.departed_date && item.status === CONSTANTS.ORDER_STATUS.PACKAGED_WAIT_TRANSIT">Departed</div>
          <div v-else v-html="orderGetStatusText(item.status).replace(' - ', '<br>')"></div>
        </template>

        <template v-slot:item.buy_tracking_status="{item}">
          <span :class="{
            'green--text' : item.buy_tracking_status === CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_SUCCESS,
            'red--text' : item.buy_tracking_status === CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_FAIL
          }">
              {{ buyTrackingGetStatusText(item.buy_tracking_status) }}
            </span>
        </template>

        <template v-slot:item.tracking_number="{item}">
          <span>
            <a target="_blank" :href="'http://tracking.epacket.vn/#/tracking/manage?tracking_no=' + item.code">{{
                item.tracking_number
              }}</a>
          </span>
          <div style="font-size: 10px; white-space: nowrap">
            {{ item.buy_tracking_number_date ? 'Buy at ' + dateFilter(item.buy_tracking_number_date) : '' }}
          </div>
        </template>
        <template v-slot:item.customer_email="{item}">
          {{ item.customer.email }}
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog.create" :width="'80%'">
      <CreateItem :onCreated="onCreated" :isCopyOrder="isCopyOrder" :showModal="dialog.create"
                  @resetFlagCopy="resetFlagCopy" :selectedItem="selectedItem"
                  :onCancel="()=>{dialog.create=false}"></CreateItem>
    </v-dialog>
    <v-dialog v-model="dialog.detail" :width="'80%'">
      <DetailItem :selectedItem="selectedItem" :onClose="()=>{dialog.detail=false}"
                  :onClickPrintLable="onClickPrintLable" @clickCopy="clickCopy"></DetailItem>
    </v-dialog>
    <v-dialog v-model="dialog.update" :width="'80%'">
      <UpdateItem :onUpdated="onUpdated" :selectedItem="selectedItem" :dialogUpdate="dialog.update"
                  :onCancel="()=>{dialog.update=false}"></UpdateItem>
    </v-dialog>
    <v-dialog v-model="dialog.delete" :width="400">
      <DeleteItem :idsToDelete="idsToDelete" :onDeleted="onDeleted" :onCancel="()=>{dialog.delete=false}"></DeleteItem>
    </v-dialog>
    <v-dialog v-model="dialog.payOrders" :width="400">
      <PayOrder :idsToPay="idsToPay" :onPaid="onPaid" :onCancel="()=>{dialog.payOrders=false}"></PayOrder>
    </v-dialog>
    <v-dialog v-model="dialog.import" :width="600">
      <ImportItem :onImported="onImported" :onCancel="()=>{dialog.import=false}"></ImportItem>
    </v-dialog>
    <v-dialog v-model="dialog.printLabel" :width="600">
      <ConfirmPrintLabel :idsToPrintLabel="idsToPrintLabel"
                         :onCancel="()=>{dialog.printLabel=false}"></ConfirmPrintLabel>
    </v-dialog>
    <v-dialog v-model="dialog.exportOrder" :width="600">
      <ModalExportOrder :picked="pickedItems" :statusSync="dialog.exportOrder" :option="tblOptions"
                        :searchData="searchFields" :onCancel="()=>{dialog.exportOrder=false}"></ModalExportOrder>
    </v-dialog>
    <v-dialog v-model="dialog.downloadLabel" :width="600">
      <ModalDownloadLabel :picked="pickedItems" :statusSync="dialog.downloadLabel" :option="tblOptions"
                        :searchData="searchFields" :onCancel="()=>{dialog.downloadLabel=false}"></ModalDownloadLabel>
    </v-dialog>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import CreateItem from "./Create";
import DetailItem from "./Detail";
import UpdateItem from "./Update";
import DeleteItem from "./Delete";
import PayOrder from "./PayOrder";
import ImportItem from "./Import";
import indexMixin from './indexMixin';
import ConfirmPrintLabel from "@/admin/pages/ManageCheckOrders/PrintLabel/ConfirmPrintLabel";
import ModalExportOrder from "@/admin/pages/ManageCheckOrders/Modal/ModalExportOrder";
import ModalDownloadLabel from "@/admin/pages/ManageCheckOrders/Modal/ModalDownloadLabel";
import MoneyFormat from "vue-money-format";
import moment from 'moment'
import CONSTANTS from '@/config/constants'

export default {
  name: 'CheckOrders',
  components: {
    ConfirmPrintLabel,
    CreateItem,
    DetailItem,
    UpdateItem,
    DeleteItem,
    PayOrder,
    ImportItem,
    MoneyFormat,
    ModalExportOrder,
    ModalDownloadLabel,
  },
  mixins: [indexMixin],
  data: () => ({
    searchFields: {
      'customer.email': {
        value: '',
        type: 'like',
        display: true,
        display_name: 'Customer Email',
        display_role: [CONSTANTS.ROLES.SUPER_ADMINISTRATOR, CONSTANTS.ROLES.RECEIVER, CONSTANTS.ROLES.PACKAGER]
      },
      name: {
        value: '',
        type: 'like',
        display: false,
        display_name: 'Order Name',
      },
      code: {
        value: '',
        type: 'like',
        display: true,
        display_name: 'Order Code',
      },
      arr_code: {
        value: [],
        type: 'like',
        display: true,
        display_name: 'Array Code',
      },
      tracking_number: {
        value: '',
        type: 'like',
        display: false,
        display_name: 'Tracking Number',
      },
      status: {
        value: '',
        type: '=',
        display: true,
        display_name: 'Order Status',
      },
      internal_warehouse_id: {
        value: '',
        type: '=',
        display: true,
        display_name: 'Internal Warehouse',
      },
      warehouse_id: {
        value: '',
        type: '=',
        display: true,
        display_name: 'Public Warehouse',
      },
      receiver_country_id: {
        value: '',
        type: '=',
        display: true,
        display_name: 'Receiver Country',
      },
      collaborator_id: {
        value: '',
        type: '=',
        display: true,
        display_name: 'Collaborator',
      },
      buy_tracking_status: {
        value: '',
        type: '=',
        display: false,
        display_name: 'Buy Tracking Status',
      },
      service_id: {
        value: '',
        type: 'like',
        display: true,
        display_name: 'Service',
      },
      search_by_receiver: {
        value: false,
        type: '=',
        display: false,
        force_hidden: true
      },
      departed_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      departed_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      delivered_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      delivered_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      received_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      received_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      created_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      created_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      buy_tracking_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      buy_tracking_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
    },
    list_code: '',
    searchWith: 'customer;orderItems;bigContainer;orderFees;payments;internalWarehouse',
    tblHeader: [
      {
        text: 'No',
        value: 'no',
        sortable: false,
        groupable: false,
        width: '55px',
        align: 'start',
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Buy Tracking Status',
        value: 'buy_tracking_status',
        sortable: false
      },
      {
        text: 'Tracking Number',
        value: 'tracking_number',
        sortable: false
      },
      {
        text: 'Total Fee',
        value: 'total_fee',
      },
      {
        text: 'Paid Fee',
        value: 'paid_fee',
      },
      {
        text: 'Remain Fee',
        value: 'remain_fee',
      },
      {
        text: 'Status',
        value: 'status',
        sortable: false,
        width: '155px',
      },
      {
        text: 'Customer email',
        value: 'customer_email',
      },
    ],
    showBuyTrackingDatePicker: false,
    showDepartDatePicker: false,
    showDeliveredDatePicker: false,
    showReceiveDatePicker: false,
    showCreatedAtOrderDatePicker: false,
    buyTrackingDates: [],
    createdDates: [],
    departDates: [],
    deliveredDates: [],
    receivedDates: [],
    idsToPay: [],
    idsToExport: [],
    isCopyOrder: false,
    showSearch: true,
    loadingCollaborators: false,
    collaborators: [],
  }),
  computed: {
    ...mapGetters({
      items: 'check_order/all',
      tblPagination: 'check_order/pagination',
      services: 'service/all',
      serviceGetName: 'service/serviceName',
      listOrderStatus: 'check_order/listOrderStatus',
      orderGetStatusText: 'check_order/getStatusText',
      buyTrackingGetStatusText: 'check_order/buyTrackingGetStatusText',
      listBuyTrackingStatus: 'check_order/listBuyTrackingStatus',
      countryById: 'country/getById',
      countries: 'country/all',
      authenticatedUser: 'user/authenticatedUser',
      warehouses: 'warehouse/all',
      warehouseById: 'warehouse/getById',
    }),
    tblHeaderFilterByRole() {
      const header = JSON.parse(JSON.stringify(this.tblHeader));
      if (this.isCustomer) {
        const findIndexStatusTracking = header.findIndex((t) => t.value === 'buy_tracking_status');
        if (findIndexStatusTracking > -1) {
          header.splice(findIndexStatusTracking, 1);
        }
        const findIndexCustomerEmail = header.findIndex((t) => t.value === 'customer_email');
        if (findIndexCustomerEmail > -1) {
          header.splice(findIndexCustomerEmail, 1);
        }
        const findIndexWarehouseName = header.findIndex((t) => t.value === 'warehouse_name');
        if (findIndexWarehouseName > -1) {
          header.splice(findIndexWarehouseName, 1);
        }
        const findIndexTrackingMaster = header.findIndex((t) => t.value === 'tracking_master');
        if (findIndexTrackingMaster > -1) {
          header.splice(findIndexTrackingMaster, 1);
        }
      } else if (this.isCollaborator || this.isPackager) {
        const findIndexAction = header.findIndex((t) => t.value === 'actions');
        if (findIndexAction > -1) {
          header.splice(findIndexAction, 1);
        }
      }
      return header;
    },
    listCountry() {
      return [{id: '', name: 'None'}, ...this.countries];
    },
    internalWarehouses() {
      return this.warehouses.filter((warehouse) => warehouse.type == 2);
    },
    listServices() {
      let listItems = [
        {text: 'All Service', value: ''}
      ]
      this.services.forEach(service => {
        listItems.push({
          text: service.name,
          value: service.id
        })
      });
      return listItems;
    },
    DateRangeText() {
      return dateArr => {
        return dateArr.join(' ~ ')
      }
    },
    departedDate() {
      return item => {
        try {
          return item.departed_date.substr(0, 10)
        } catch (e) {
          return ''
        }
      }
    },
    deliveredDate() {
      return item => {
        try {
          return item.delivered_date.substr(0, 10)
        } catch (e) {
          return ''
        }
      }
    },
    createdDate() {
      return item => {
        try {
          return item.created_at.substr(0, 10)
        } catch (e) {
          return ''
        }
      }
    },
    canPayMultiOrders() {
      return this.pickedItems.filter(order => {
        let payments = order.payments.filter(p => p.status === this.CONSTANTS.PAYMENT_STATUS.PAID && p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER);
        let paidFee = 0;
        payments.forEach(p => paidFee += parseFloat(p.amount));
        let totalFee = this.getTotalFee(order);
        return parseInt(totalFee) - parseInt(paidFee) === 0;
      }).length === 0
    },
    canBuyTracking() {
      return true;
      /*return this.pickedItems.filter(item => {
        return item.buy_tracking_status === this.CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_SUCCESS || item.buy_tracking_status === this.CONSTANTS.TRACKING_NUMBER_BUY_STATUS.NEED_BUY
      }).length === 0*/
    },

    getPaidFee() {
      return order => {
        let payments = order.payments.filter(p => p.status === this.CONSTANTS.PAYMENT_STATUS.PAID && p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER);
        let paidFee = 0;
        payments.forEach(p => paidFee += parseFloat(p.amount));
        return paidFee;
      }
    },
    getTotalFee() {
      return order => {
        let totalFee = 0;
        order.order_fees.forEach(f => totalFee += parseFloat(f.fee_amount));
        return parseInt(totalFee);
      }
    },
    getRemainFee() {
      return order => {
        let payments = order.payments.filter(p => p.status === this.CONSTANTS.PAYMENT_STATUS.PAID && p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER);
        let paidFee = 0;
        payments.forEach(p => paidFee += parseFloat(p.amount));
        let totalFee = this.getTotalFee(order);
        return totalFee - paidFee;
      }
    },
    toggleableSearchFields() {
      return Object.keys(this.searchFields).filter(sf => this.searchFields[sf].force_hidden !== true);
    },
    canSearchCustomer() {
      return this.isSuperAdmin || this.isSale || this.isManager || this.isSupervisor;
    },
    totalRemainingFee() {
      let sum = 0;
      this.pickedItems.forEach(order => {
        sum += this.getRemainFee(order)
      });
      return sum;
    }
  },
  methods: {
    ...mapActions({
      getListItems: 'check_order/get',
      exportListItems: 'check_order/exportItem',
      requestBuyTrackingNumber: 'check_order/requestBuyTrackingNumber',
      getUsers: "user/getUsers",
    }),
    onClickPayOrder(props) {
      this.dialog.payOrders = true;
      this.idsToPay = [props.item.id];
    },
    clickCopy() {
      this.dialog.detail = false;
      this.dialog.create = true;
      this.isCopyOrder = true;
    },
    onClickPrintLable() {
      if (this.selectedItem && this.selectedItem.id) {
        this.dialog.detail = false;
        this.dialog.printLabel = true;
        this.idsToPrintLabel = [this.selectedItem.id]
      }
    },
    resetFlagCopy() {
      this.isCopyOrder = false;
    },
    dateFilter(value) {
      if (!value) return;
      value = moment(value);
      return value.format('YYYY-MM-DD hh:mm');
    },
    exportItems() {
      this.dialog.exportOrder = true;
    },
    async onClickDownloadLabel() {
      this.dialog.downloadLabel = true;
    },
    clearBuyTrackingSearch() {
      this.searchFields.buy_tracking_date_from.value = '';
      this.searchFields.buy_tracking_date_to.value = '';
    },
    clearInternalWarehouseSearch() {
      this.searchFields.internal_warehouse_id.value = '';
    },
    clearCreatedOrderSearch() {
      this.searchFields.created_date_to.value = '';
      this.searchFields.created_date_from.value = '';
    },
    clearDepartDateSearch() {
      this.searchFields.departed_date_to.value = '';
      this.searchFields.delivered_date_from.value = '';
    },
    clearDeliveredDateSearch() {
      this.searchFields.delivered_date_to.value = '';
      this.searchFields.delivered_date_from.value = '';
    },
    clearReceivedDateSearch() {
      this.searchFields.received_date_to.value = '';
      this.searchFields.received_date_from.value = '';
    },
    async getCollaboratorsFromApi() {
      this.loadingCollaborators = true;
      let request = {
        params: {
          limit: -1,
          page: this.page,
          silent: true,
          with: 'role;socialAccount',
          search: 'role_id:' + this.CONSTANTS.ROLES.COLLABORATOR,
          searchFields: 'role_id:=',
          searchJoin: 'AND'
        }
      }
      let collaboratorsData = await this.getUsers(request);
      this.collaborators = [...this.collaborators, ...collaboratorsData.data.data];
      request.params.search = 'role_id:' + this.CONSTANTS.ROLES.SALE;
      let salesData = await this.getUsers(request);
      this.collaborators = [...this.collaborators, ...salesData.data.data];
      this.loadingCollaborators = false;
    },
  },
  watch: {
    departDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.departed_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.departed_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    },
    deliveredDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.delivered_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.delivered_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    },
    receivedDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.received_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.received_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    },
    createdDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.created_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.created_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    },
    buyTrackingDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.buy_tracking_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.buy_tracking_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // if(!this.authenticatedUser.id_card || !this.authenticatedUser.phone || this.authenticatedUser.id_card.trim().length === 0 || this.authenticatedUser.phone.trim().length === 0) {
    //   this.noticeWarning('Phone, Identity Card required!');
    //   this.$router.push({name: 'Profile'})
    //   return;
    // }
    this.searchFields.search_by_receiver.value = this.isReceiver
    this.getCollaboratorsFromApi();
  }
}
</script>
<style scoped lang="scss">
.list-orders::v-deep {
  .v-data-table-header th {
    white-space: nowrap;
  }

  .order-customer-note {
    display: flex;
    max-width: 150px;

    .truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.block-item-name {
  .item-name {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .item-code {
    font-size: 0.8rem;
  }
}

.internal-warehouse {
  font-size: 0.8rem;
}

.item-service-name {
  white-space: nowrap;
}

.switch-search::v-deep {
  .v-messages {
    display: none;
  }
}

.btn-unpaid-order {
  ::v-deep span {
    color: #fff !important;
  }
}
</style>
